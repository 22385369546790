import React, { createRef } from 'react';
import Select, { components } from 'react-select'
import Shuffle from 'shufflejs';

import MEMBERS from '../../constants/members';

function sortDate(element) {
  return element.getAttribute('data-date-published');
}

function sortPopular(element) {
  return element.getAttribute('data-view-count');
}

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

class VideoControls extends React.Component {
  state = {
    sort: {
      reverse: true,
      by: sortDate,
    },
  }

  customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial"
    })
  };

  multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(selected => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
    const val = `${label}${labelSuffix}`;
    return val;
  };

  handleFilterOnChange = (value) => {
    const { sort } = this.state;
    let filters = '';

    if (value) { 
      filters = value.reduce(function(acc, obj) {
        acc.push(obj.label);
        return acc;
      }, []);
    }
  
    window.shuffle.filter(filters, sort); 
  }

  handleSortOnChange = (selected) => {
    const { value } = selected || {};
    let options;
  
    switch (value) {
      case 'new': 
        options = {
          reverse: true,
          by: sortDate,
        };
        break;
      case 'old': 
        options = {
          by: sortDate,
        };
        break;
        case 'popular': 
          options = {
            reverse: true,
            compare: function (a, b) {
              var countA = parseInt(a.element.getAttribute('data-view-count'), 10);
              var countB = parseInt(b.element.getAttribute('data-view-count'), 10);
              return countA - countB;
            },
          };
          break;
        case 'not-popular': 
          options = {
            compare: function (a, b) {
              var countA = parseInt(a.element.getAttribute('data-view-count'), 10);
              var countB = parseInt(b.element.getAttribute('data-view-count'), 10);
              return countA - countB;
            },
          };
          break;
    }
  
    this.setState({
      sort: options,
    }, () => {
      window.shuffle.sort(options);
    })
  }

  /**
   * Filter the shuffle instance by items with a title that matches the search input.
   * @param {Event} evt Event object.
   */
  handleInputOnChange(evt) {
    const searchText = evt.target.value.toLowerCase();
    window.shuffle.filter((element, shuffle) => {
      // If there is a current filter applied, ignore elements that don't match it.
      if (shuffle.group !== Shuffle.ALL_ITEMS) {
        // Get the item's groups.
        const groups = JSON.parse(element.getAttribute('data-groups'));
        const isElementInCurrentGroup = groups.indexOf(shuffle.group) !== -1;
        // Only search elements in the current group
        if (!isElementInCurrentGroup) {
          return false;
        }
      }
      const titleElement = element.getAttribute('data-title');

      if (!titleElement) return;

      const titleText = titleElement.toLowerCase().trim();
      return titleText.indexOf(searchText) !== -1;
    });
  }

  render() {
    const { shuffle } = this.props;
    const {
      CustomOption,
      multiValueContainer,
      customStyles,
      ValueContainer
    } = this;

      const options = Object.keys(MEMBERS).reduce(function(acc, member)  {
        acc.push(MEMBERS[member].select);
        return acc;
      }, []);

      return (
        <div className="video-controls">
          <div className="container">
            <div className="columns">
          <div className="column">
            <label className="video-controls__label">Sort:</label>
            <Select 
              defaultValue={{
                value: 'new',
                label: 'Date Added (Newest)',
              }}
              options={[
                {
                  value: 'new',
                  label: 'Date Added (Newest)',
                }, {
                  value: 'old',
                  label: 'Date Added (oldest)',
                }, {
                  value: 'popular',
                  label: 'Most Popular',
                }, {
                  value: 'not-popular',
                  label: 'Least Popular',
                }
              ]} 
              placeholder='Sort by...'
              onChange={this.handleSortOnChange}
            />
          </div>
          <div className="column">
          <label className="video-controls__label">Filter:</label>
            <Select 
              isMulti
              options={options} 
              placeholder='Select player(s)'
              onChange={this.handleFilterOnChange}

              hideSelectedOptions={false}
              styles={customStyles}
              formatGroupLabel={formatGroupLabel}
              isSearchable={false}
              components={{
                MultiValueContainer: multiValueContainer
              }}
            />
          </div>
          <div className="column">
          <label className="video-controls__label">Search:</label>
          <input 
                className="video-controls__input" 
                type="search" 
                id="filters-search-input" 
                placeholder="Custom search..."
                onChange={this.handleInputOnChange}
              />
          </div>
          </div>
          </div>
        </div>
      );
  }
}

export default VideoControls; 

