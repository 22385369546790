const MEMBERS = {
  AVERAGE_STATUS: {
    select: {
        value: 'averagestatus', 
        label: 'AverageStatus',
    }
  }, 
  LEGEND_AF: {
    select: {
        value: 'legendaf', 
        label: 'LegendAF',
    }
  }, 
  BEERSANDBACON: {
    select: {
        value: 'beersandbacon', 
        label: 'BeersAndBacon',
    }
  }, 
  ICETEAMIX: {
    select: {
        value: 'iceteamix', 
        label: 'Iceteamix',
    }
  }, 
  WAKOBAKO: {
    select: {
        value: 'wakobako', 
        label: 'WakoBako',
    }
  },
  BLAZINGCAMELS: {
    select: {
        value: 'blazingcamels', 
        label: 'BlazingCamels',
    }
  },
  DARBOUND: {
    select: {
        value: 'darbound', 
        label: 'Darbound',
    }
  }, 
  PATRICK: {
    select: {
        value: 'patrickthechamp', 
        label: 'PatrickTheChamp',
    }
  }, 
  LANCEAKIRO: {
    select: {
        value: 'lance akiro', 
        label: 'Lance Akiro',
    }
  }
}

export default MEMBERS;