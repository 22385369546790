import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import Shuffle from 'shufflejs';

import VideoItem from '../VideoItem';

function sortDate(element) {
  return element.getAttribute('data-date-published');
}

// Create the component which will use Shuffle.
class VideoList extends Component {
  element = React.createRef();
  sizer = React.createRef();
  shuffle;

  componentDidMount() {
    const { current: currentElement } = this.element || {};

    if (!currentElement) return;

    this.shuffle = new Shuffle(currentElement, {
      itemSelector: '.youtube-shuffle',
      filterMode: Shuffle.FilterMode.ALL,
      initialSort: {
        reverse: true,
        by: sortDate,
      },
    });

    window.shuffle = this.shuffle;
  }

  render() {
    const { handleVideoSelect, videos } = this.props;
    return (
      <>
        <div ref={this.element} className="columns is-multiline">
          {Object.keys(videos).map((video) => {
            return <VideoItem key={video.id} video={videos[video]} handleVideoSelect={handleVideoSelect} />
          })}
        </div>
      </>
    );
  }
}
  
export default VideoList;   
