import React from 'react';
import ReactTimeAgo from 'react-time-ago'

import MEMBERS from '../../constants/members';

const VideoItem = ({video , handleVideoSelect}) => {
    const { 
        publishedAt, 
        statistics,
        tags = [],
        title, 
    } = video || {};
    const { viewCount } = statistics || {};
    const members = tags.reduce(function(result, tag) {
        const normalized = tag.trim().toLowerCase();

        const test = Object.keys(MEMBERS).reduce(function(result, member) {
            const { select } = MEMBERS[member];
            const { label, value } = select || {};

            if (normalized === value) result.push(label);

            return result;
        }, []);

        if (test.length) result.push(...test);

        return result;
    }, []);

    return (
        <div 
            className="column is-one-quarter youtube-shuffle"
            data-date-published={publishedAt}
            data-groups={JSON.stringify(members)}
            data-title={title}
            data-view-count={viewCount}
        >
            <div className='youtube-preview' onClick={ () => handleVideoSelect(video)} >
                <div className="youtube-preview__hover">
                    <div className="youtube-preview__title">{title.split('—')[0].trim()}</div>
                    <div className="youtube-preview__meta"><ReactTimeAgo date={new Date(publishedAt)}/><div className="youtube-preview__seperator">—</div>{viewCount} views</div>
                </div>
                <img className='youtube-preview__image' src={video.thumbnails.medium.url} alt={video.description}/>
            </div>
        </div>
    )
};
export default VideoItem;