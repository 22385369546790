import React from 'react';
import ReactTimeAgo from 'react-time-ago'

import MEMBERS from '../../constants/members';
import youtubeData from '../../data/youtube.json';
import VideoFrame from '../youtube/VideoFrame';

import VideoControls from '../VideoControls';

const VideoDetail = ({video}) => {
    if (!video) video = youtubeData[Object.keys(youtubeData)[0]]; 

    const { 
        description, 
        game,
        id, 
        playlist,
        publishedAt, 
        title, 
        episode,
    } = video || {};
    const formattedTitle = game ? title.slice(0, title.indexOf('—')) : title;
    const nth = function(date) {
        if (date > 3 && date < 21) return 'th';

        switch (date % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
        }
    }
    const monthName = function(date) {
        const list = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

        return list[date.getMonth()];
    };
    const date = new Date(publishedAt);
    const formattedDate = monthName(date) + " " + date.getDate() + nth(date.getDate()) + ", " +  date.getFullYear();

    const playing = () => {
        if (!game) return '';
        
        if (episode) {
            return <> <a href={playlist}>{game}</a> • <a href={`https://youtube.com/watch?v=${id}`}>#{episode}</a></>;
        }

        return <><a href={playlist}>{game}</a></>;
    }



    return (
        <div class="container video-detail">
            <div className="columns is-gapless">
                <div className="column is-full-mobile is-full-tablet is-half-desktop">
                    <VideoFrame video={video} />
                </div>
                <div className="column is-hidden-touch some">
                    <div className="youtube-detail">
                        <div className="youtube-detail__title"><a href={`https://youtube.com/watch?v=${id}`}>{formattedTitle}</a></div>
                        <div className="youtube-detail__published">Published <ReactTimeAgo date={new Date(publishedAt)}/> • {playing()}</div>
                        <div className="youtube-detail__description">{description}</div>
                        <div className="youtube-detail__subscribe"><div class="g-ytsubscribe" data-channelid="UCreSDO96JDAhvMJClTjG66g" data-layout="default" data-theme="dark" data-count="default"></div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoDetail; 

