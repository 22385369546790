import React from 'react';

const VideoFrame = ({video}) => {
    return (
        <div className="youtube-video-player">
            <div className='youtube-video-player__container'>
                <iframe src={`https://www.youtube.com/embed/${video.id}`} allowFullScreen title='Video player'/>
            </div>
        </div>
    );
}

export default VideoFrame; 



