import React from 'react';
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import Shuffle from 'shufflejs';

import Layout from '../components/Layout'
import SearchBar from '../components/youtube/Searchbar';
import VideoList from '../components/youtube/VideoList';
import VideoDetail from '../components/VideoDetail';
import VideoControls from '../components/VideoControls';
import youtubeData from '../data/youtube.json';

JavascriptTimeAgo.addLocale(en)

class App extends React.Component {
    state = {
        videos: [],
        selectedVideo: null
    }

    handleVideoSelect = (id) => {
        this.setState({
            selectedVideo: id,
        })
    }

    render() {

        return (
            <Layout>
              <VideoDetail video={this.state.selectedVideo} />
              <VideoControls />
              <div class="container">
              <VideoList 
                handleVideoSelect={this.handleVideoSelect} 
                videos={youtubeData}
              />
              </div>
            </Layout>
        );

    }
}

export default App;